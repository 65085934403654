import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useFetch from '../../useFetch';
import Paginate from '../../Paginate';
import DeletePopup from './../DeletePopup';
import { Table, Dropdown, Button } from 'react-bootstrap';
import zIndex from '@mui/material/styles/zIndex';

const PastureIndex = () => {
    document.title = "Pastures | Mowing";

    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL + '/pastures');
    const [items, setItems] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteItem, setDeleteItem] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sorting order

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.pastures?.data ?? []);
        setSortedItems(response?.pastures?.data ?? []);
        setMeta(response?.pastures?.meta ?? []);
    }, [response])

    useEffect(() => {
        // Sort items whenever sortOrder changes
        sortItems();
    }, [sortOrder])

    const sortItems = () => {
        const sorted = [...items].sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.mowing_end_time) - new Date(b.mowing_end_time);
            } else {
                return new Date(b.mowing_end_time) - new Date(a.mowing_end_time);
            }
        });
        setSortedItems(sorted);
    }

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    return (
        <div className="container-fluid">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}

            <div className="card shadow mb-4">
                <div className="card-header d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Pastures</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="create">Add New</Link></h6>
                </div>
                <div className="card-body">
                    {fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{fetchError}</strong></h3></div>}

                    {isLoading && <h5 className="text-center my-3">Loading Data</h5>}

                    {!fetchError && !isLoading ? (
                        <>
                            {sortedItems?.length === 0 ? (
                                <h5 className="text-center text-danger my-3">No data found{currentPage > 0 ? ' on this page' : ''}</h5>
                            ) : (
                                <div className="d-flex mb-3 justify-content-end">
                                    <Button variant="primary" onClick={toggleSortOrder}>
                                        {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
                                    </Button>
                                </div>
                            )}

                            <Table responsive bordered hover>
                                {/* Table header */}
                                <thead>
                                    <tr style={{zIndex:'10'}}>
                                        <th style={{ width: '50px' }}>ID</th>
                                        <th style={{ minWidth: '100%' }}>Pasture</th>
                                        <th style={{ width: '1px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody style={{zIndex:'1000'}}>
                                    {/* Table rows */}
                                    {sortedItems.map((item) => (
                                    //   <tr  style={{ border: item.completion_status === 100 ? '2px solid green' : 'none' }} key={item.id}>
                                    <tr  key={item.id}>
                                      <td>
                                          {item.id}
                                      </td>
                                      <td>
                                          {/* Pasture details */}
                                          {item.is_problem ? <p className='badge badge-danger mb-2'><strong>HAS PROBLEM</strong></p> : null}
                                          {item.completion_status === 100 ? <p className='badge badge-success mb-2'><strong>Completed</strong></p> : null}
                                          <p className='mb-0'><strong>{item.address}</strong> ({item.coordinates}) <a href={`https://www.google.com/maps?q=${item.coordinates}`} target='_blank'>View on Google Map</a></p>
                                          <p className='mb-0'>Surface: <strong>{item.surface}m<sup>2</sup></strong></p>
                                          <p className='mb-0'>Completed: <strong>{item.completion_status}</strong></p>
                                          <p className='mb-0'>Last Mowed: <strong>{item.mowing_end_time ? new Date(item.mowing_end_time).toLocaleString() : "Not yet"}</strong></p>
                                          <p className='mb-0'>Emergency Level: <strong>{item.emergency_level}</strong></p>
                                          <p className='mb-0'>Worker's Comments: <strong>{item?.worker_comments || 'No comments'}</strong></p>
                                          <p className='mb-0'>Pasture's Images:</p>
                                          <div className="d-flex">
                                              {typeof item.images === 'string' && (() => {
                                                  try {
                                                      const imageArray = JSON.parse(item.images);
                                                      return Array.isArray(imageArray) ? (
                                                          imageArray.map((imageName, index) => (
                                                              <a
                                                                  key={index}
                                                                  href={imageName}
                                                                  //   URL WILL BE UPDATED WHILE PUTTING ON SERVER
                                                                //   href={`http://localhost/mowing-api/public/storage/${imageName}`}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                              >
                                                                  <img
                                                                      src={imageName}
                                                                      //   URL WILL BE UPDATED WHILE PUTTING ON SERVER
                                                                    //   src={`http://localhost/mowing-api/public/storage/${imageName}`}
                                                                      alt={`Image-${index}`}
                                                                      style={{ width: '100px', height: '100px', marginRight: '10px', objectFit: 'contain' }}
                                                                  />
                                                              </a>
                                                          ))
                                                      ) : null;
                                                  } catch (error) {
                                                      console.error('Error parsing images:', error);
                                                      return null;
                                                  }
                                              })()}
                                          </div>
                                          {item.manager_comments && <p className='mb-0'>Comment: {item.manager_comments}</p>}
                                      </td>
                                      <td style={{ padding: '0.3rem' }}>
                                          {/* Dropdown actions */}
                                          <Dropdown>
                                              <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                  <Dropdown.Item as={Link} to={`edit/${item.id}`}>Modify</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => setDeleteItem({ id: item.id, url: 'pastures/' + item.id })} className="text-danger">Delete</Dropdown.Item>
                                              </Dropdown.Menu>
                                          </Dropdown>
                                      </td>
                                  </tr>
                                  
                                    ))}
                                </tbody>
                            </Table>

                            {/* Pagination */}
                            {meta?.last_page > 1 && (
                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />
                            )}

                            {/* Records count */}
                            <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default PastureIndex;
